import React from "react";
import { graphql } from "gatsby";
import SelfLearnTemplate from "../templates/self-learn";

const environment = {
  lang: "nl",
  path: "/nederlandse-grammatica",
  altHrefLang: [
    {
      lang: "nl",
      path: "/nederlandse-grammatica",
    },
    {
      lang: "en",
      path: "/en/dutch-grammar",
    },
  ],

  seo: {
    title:
      "Leer gratis online Nederlands met grammatica, uitleg en oefeningen.",
    description: "Leer Nederlands waar en wanneer je wilt.",
  },
  theme: "orange",
};

const SelfLearnNl = (props) => {
  return <SelfLearnTemplate {...props} environment={environment} />;
};

export default SelfLearnNl;

export const query = graphql`
  query GrammarItemsQueryNl {
    pills: allContentfulGrammarPill(
      filter: { node_locale: { eq: "nl" } }
      sort: { fields: [difficulty, title], order: [ASC, ASC] }
    ) {
      edges {
        node {
          title
          slug
          difficulty
          node_locale
          contentful_id
          __typename
          categories {
            title
          }
          relatedExercises {
            title
          }
          relatedQuiz {
            ...QuizFragment
          }
        }
      }
    }
  }
`;
